#bio-section{
  margin-bottom: 30px;
  padding-top: 80px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.bio-container{
  padding: 10px;
  border: 1px gray solid;
  background-color: white;
  -moz-box-shadow:    3px 3px 5px 3px #ccc;
  -webkit-box-shadow: 3px 3px 5px 3px #ccc;
  box-shadow:         3px 3px 5px 3px #ccc;
}

img.bio-pic{
  border-radius: 10px;
  display: block;
  width: 60vw;
  margin: 0 auto 15px;
}

.bio {
  display: block;
  width: 60vw;
  margin: 5px auto;
}

div.bio p {
  margin: 5px;
}

#bio-section h2 {
  font-size: 35px;
}

@media screen and (min-width: 375px){
  .bio p {
    font-size: 20px;
  }
  
}

@media screen and (min-width: 768px){
  #bio-section{
    padding-top: 90px;
  }

  #bio-section h2 {
    font-size: 44px;
  }
}