#project-section {
  width: 90%;
  padding-top: 80px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-content: center;
}

h2, h3 {
 text-align: center;
}

h2 {
  font-size: 36px;
  margin-top: 0;
}

h3 {
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.project {
  padding: 10px;
  border: 1px solid gray;
  background-color: white;
  opacity: 1;
  margin-bottom: 25px;
  -moz-box-shadow:    3px 3px 5px 3px #ccc;
  -webkit-box-shadow: 3px 3px 5px 3px #ccc;
  box-shadow:         3px 3px 5px 3px #ccc;
}

.project > p {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.image {
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

h4 {
  text-align: center;
  margin-top: 0;
}

h4 .icon{
  height: 30px;
  margin-left: 2px;
  margin-right: 2px;
}

.img-container{
  overflow: hidden;
}

.img-container img {
  transition: transform 2s ease;
}

.img-container:hover img {
  transform: scale(1.05);
}

ul.links {
  text-align: center;
  justify-content: center;
  align-content: center;
}

a.link {
  text-decoration: none;
}

a.link:hover {
  text-decoration: underline;
}

#nodejs {
  padding-right: 2px;
}

@media screen and (min-width: 768px){
  #project-section h2 {
    font-size: 44px;
    margin-bottom: 25px;
  }

  h3 {
    font-size: 36px;
  }

  h4 .icon{
    height: 40px;
  }
}