h2.contact-me {
  width: 50%;
  font-size: 30px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

ul.contact-icons {
 text-align: center;
 margin-bottom: 25px;
}

.contact-icon{
  height: 30px;
  -moz-transition: top 2s ease-in-out;
	-webkit-transition: top 2s ease-in-out;
	-ms-transition: top 2s ease-in-out;
  transition: top 2s ease-in-out;
}

.contact-icon:hover {
  position: relative;
  top: -7px;
}

#email {
  padding-top: 2px;
}

@media screen and (min-width: 768px){
  h2.contact-me {
    font-size: 36px;
  }

}