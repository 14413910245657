* {
  box-sizing: border-box;
}

body {
  font-family: 'OpenSans';
  background-color: #40414711;
  color: black;
}

main {
  margin-left: auto;
  margin-right: auto;
}